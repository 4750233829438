<template>
    <div class="board__list">
        <head-tab tit="커뮤니티">
            <v-tabs v-model="filter.tab" color="grey-9" hide-slider class="v-tabs--punch">
                <v-tab @click="input('community', 0)">All</v-tab>
                <v-tab v-for="category, index in categories" :key="index" @click="input(category?.code, index + 1)">{{ category?.name }}</v-tab>
            </v-tabs>
        </head-tab>

        <div class="mb-20 mb-lg-30">
            <v-row class="row--x-small">
                <v-spacer />
                <v-col cols="auto">
                    <v-select v-model="filter.sortKey" :items="sortKeys" class="v-input--small w-100px" outlined hide-details @change="search()"></v-select>
                </v-col>
            </v-row>
        </div>

        <div class="mb-20 mb-lg-40">
            <v-card flat v-for="board in boards" :key="board._id" @click="move(board)" class="py-12 py-lg-24 border-bottom board-item">
                <v-row class="row--large">
                    <v-col cols="12" md="" order="2" order-md="1">
                        <board-tit small :image="board?.content?.includes('<img src')" :category="setCategoryName(board)" :tit="board?.subject"></board-tit>
                        <!-- 내용 -->
                        <!-- <p v-html="board.content.replace(/\n/g, '<br>')" class="mt-8 mt-lg-16 mb-20 mb-lg-40 ellip--3 page-text grey-6--text" /> -->
                        <p class="mt-8 mt-lg-16 mb-20 mb-lg-40 ellip--2 page-text--lg grey-6--text line-height-15" style="white-space: pre-line">
                            {{ board?.subContent }}
                        </p>                
                        <v-row align="center" class="row--x-small">
                            <v-col>
                                <v-row align="center" class="row--x-small page-text grey-6--text">
                                    <v-col cols="auto">
                                        <board-user-level :user="board?.user" />
                                    </v-col>
                                    <v-col cols="auto">
                                        <p>{{ $dayjs(board?.createdAt).format("YYYY.MM.DD") }}</p>
                                    </v-col>
                                </v-row>
                            </v-col>
                            <v-col cols="12" md="auto">
                                <board-count recommend :viewCount="board?.viewCount" :likeCount="board?.likeCount" :dislikeCount="board?.dislikeCount" :commentCount="board?.commentCount" />
                            </v-col>
                        </v-row>
                    </v-col>
                    <v-col v-if="board?.content?.includes('<img src')" cols="12" md="auto" order="1" order-md="2" class="mb-4 mb-md-0">
                        <v-card elevation="0" rounded :width="$vuetify.breakpoint.mdAndUp ? 160 : 100">
                            <div class="thumb" style="padding-top: 100%;">
                                <div class="thumb__inner">
                                    <v-img :src="selectImage(board.content)" class="thumb__inner"></v-img>
                                </div>
                            </div>
                        </v-card>
                    </v-col>
                </v-row>
            </v-card>
        </div>

        <v-row class="row--x-small flex-md-row-reverse">
            <v-col cols="12" md="auto" class="d-flex justify-end mb-10 mb-md-0">
                <v-btn v-if="filter.tab !== 0" outlined color="primary" class="h-lg-40px" @click="certification">글쓰기</v-btn>
            </v-col>
            <v-spacer />
            <v-col cols="12" md="auto">
                <v-row class="row--x-small search--primary">
                    <v-spacer></v-spacer>
                    <v-col cols="12" md="auto">
                        <v-select v-model="filter.searchKey" :items="searchKeys" class="v-input--small w-100 w-md-120px" outlined hide-details></v-select>
                    </v-col>
                    <v-col cols="9" md="auto">
                        <v-text-field v-model="filter.searchValue" class="v-input--small w-100 w-md-240px" outlined hide-details placeholder="검색어를 입력하세요." :disabled="!filter.searchKey"/>
                    </v-col>
                    <v-col cols="3" md="auto">
                        <v-btn class="h-100 w-100 min-w-md-60px" color="grey-6" @click="search()">
                            <span class="white--text font-size-14">검색</span>
                        </v-btn>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>

        <div class="v-pagination-wrap">
            <v-pagination v-model="page" :length="pageCount" :total-visible="11" class="mt-4 mb-12" @input="search()"></v-pagination>
        </div>
    </div>
</template>

<script>
import api from "@/api";
import HeadTab from "@/components/client/sub/head-tab.vue";
import BoardTit from "@/components/client/board/board-item__tit.vue";
import SearchPrimary from "@/components/client/board/search--primary.vue";
import BoardItemPrimary from "@/components/client/board/board-item--primary.vue";
import BoardUserLevel from "@/components/client/board/board-user-level.vue";
import BoardCount from "@/components/client/board/board-count.vue";


export default {
    components: {
        HeadTab,
        BoardTit,
        SearchPrimary,
        BoardItemPrimary,
        BoardUserLevel,
        BoardCount,
    },

    data() {
        return {
            categories: null,

            boards: [],

            page: 1,
            limit: 4,
            pageCount: 0,

            filter: {
                sortKey: null,
                code: this.$route.query.code || null,
                searchKey: this.$route.query.searchKey || "subjectcontent",
                searchValue: this.$route.query.searchValue || null,
                tab: this.$route.query.tab || null
            },

            sortKeys: [
                { text: "정렬순", value: null },
                { text: "조회순", value: "viewCount" },
                { text: "추천순", value: "likeCount" },
                { text: "최신순", value: "createdAt" },
            ],

            searchKeys: [
                { text: "제목 + 내용", value: "subjectcontent" },
                { text: "제목", value: "subject" },
            ],
        };
    },
    async mounted() {
        this.filter.tab = Number(this.$route.query.tab)
        await this.init();
    },
    methods: {
        async init() {
            let { categories } = await api.v1.boards.categories.gets({ params: { depth: 1, code: "community" } });
            this.categories = categories[0].children;
            await this.search();
        },
        async search(routable = false) {
            if(routable) {
                this.$router.push({
                    query: {
                        ...this.filter,
                        page: this.page
                    }
                })
                return
            }
            let { summary, boards } = await api.v1.boards.gets({
                headers: {
                    skip: (this.page - 1) * this.limit,
                    limit: this.limit,
                },
                params: { ...this.filter },
            });
            this.boards = boards;
            this.pageCount = Math.ceil(summary.totalCount / this.limit) || 1;
        },
        async input(code, tab) {
            this.filter.searchKey = "subjectcontent";
            this.filter.searchValue = null;
            this.filter.sortKey = null;
            this.filter.code = code;
            this.filter.tab = tab;
            this.page = 1;

            await this.search();
        },
        move(board) {
            this.$router.push(`/community/board/${board._id}`);
        },
        setCategoryName(board) {
            if (this.$route.query.code === "community" || this.$route.query.code === "popularity") {
                if (board?.code === "free") {
                    return `자유/${board?.category?.name}`;
                }
                if (board.code === "certification") {
                    return `인증/${board?.category?.name}`;
                }
                if (board.code === "challenge") {
                    return `챌린지/${board?.category?.name}`;
                }
            } else {
                return board?.category?.name;
            }
        },
        certification() {
            let { levelCode } = this.payload;
            if(!this.logon) {
                alert("로그인 후 이용할 수 있습니다")
                return;
            }
            if(this.$route.query.code === "certification") {
                if(levelCode === "normal") {
                    alert("인증게시판은 유경험자, 전문가 회원만 작성 할 수 있습니다.")
                } else {
                    this.$router.push(`/community/board/create?code=${this.filter.code}`)
                }
            } else {
                this.$router.push(`/community/board/create?code=${this.filter.code}`)
            }
        },
        selectImage(content) {
            const imgSrcRegex = /<img[^>]*src="([^"]*)"/g;
            const imgSrcs = [];
            let match;
            while ((match = imgSrcRegex.exec(content)) !== null) {
                imgSrcs.push(match[1]);
            }
            return imgSrcs[0];
        }
    },
    computed: {
        accessToken(){
            return this.$store.state.accessToken;
        },
        logon(){ 
            return !!this.accessToken 
        },
        payload() {
            return this.$store.state.payload;
        }
    },
    watch: {
        async "$route.query.code"() {
            this.filter.code = this.$route.query.code;
            await this.search();
        },
        "$route.query.tab"() {
            this.filter.tab = Number(this.$route.query.tab)
        }
    },
};
</script>

<style lang="scss" scoped></style>
