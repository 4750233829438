<template>
    <div class="mb-20 mb-lg-30">
        <div class="tit-warp tit-wrap--lg text-center">
            <h2 class="tit tit--lg">{{tit}}</h2>
        </div>
        <slot />
    </div>
</template>

<script>

export default{
    props : {
        tit : {type :String, default : ""},
    },
    components: {
    },
    data: () => {
        return {
        }
    },
	created() {
	},
}
</script>

<style lang="scss" scoped>
@media (min-width:576px){
}
@media (min-width:768px){
}
@media (min-width:1024px){
    .head-tab{
        position: relative;
        &::before{
            content: "";
            display: block;
            position: absolute;
            width: 100%;
            height: 1px;
            background-color: var(--v-primary-base);
            bottom: 0;
            left: 0;
        }
    }
}
@media (min-width:1200px){
}
</style>