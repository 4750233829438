var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "board__list"
  }, [_c('head-tab', {
    attrs: {
      "tit": "커뮤니티"
    }
  }, [_c('v-tabs', {
    staticClass: "v-tabs--punch",
    attrs: {
      "color": "grey-9",
      "hide-slider": ""
    },
    model: {
      value: _vm.filter.tab,
      callback: function ($$v) {
        _vm.$set(_vm.filter, "tab", $$v);
      },
      expression: "filter.tab"
    }
  }, [_c('v-tab', {
    on: {
      "click": function ($event) {
        return _vm.input('community', 0);
      }
    }
  }, [_vm._v("All")]), _vm._l(_vm.categories, function (category, index) {
    return _c('v-tab', {
      key: index,
      on: {
        "click": function ($event) {
          return _vm.input(category === null || category === void 0 ? void 0 : category.code, index + 1);
        }
      }
    }, [_vm._v(_vm._s(category === null || category === void 0 ? void 0 : category.name))]);
  })], 2)], 1), _c('div', {
    staticClass: "mb-20 mb-lg-30"
  }, [_c('v-row', {
    staticClass: "row--x-small"
  }, [_c('v-spacer'), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-select', {
    staticClass: "v-input--small w-100px",
    attrs: {
      "items": _vm.sortKeys,
      "outlined": "",
      "hide-details": ""
    },
    on: {
      "change": function ($event) {
        return _vm.search();
      }
    },
    model: {
      value: _vm.filter.sortKey,
      callback: function ($$v) {
        _vm.$set(_vm.filter, "sortKey", $$v);
      },
      expression: "filter.sortKey"
    }
  })], 1)], 1)], 1), _c('div', {
    staticClass: "mb-20 mb-lg-40"
  }, _vm._l(_vm.boards, function (board) {
    var _board$content, _board$content2;
    return _c('v-card', {
      key: board._id,
      staticClass: "py-12 py-lg-24 border-bottom board-item",
      attrs: {
        "flat": ""
      },
      on: {
        "click": function ($event) {
          return _vm.move(board);
        }
      }
    }, [_c('v-row', {
      staticClass: "row--large"
    }, [_c('v-col', {
      attrs: {
        "cols": "12",
        "md": "",
        "order": "2",
        "order-md": "1"
      }
    }, [_c('board-tit', {
      attrs: {
        "small": "",
        "image": board === null || board === void 0 ? void 0 : (_board$content = board.content) === null || _board$content === void 0 ? void 0 : _board$content.includes('<img src'),
        "category": _vm.setCategoryName(board),
        "tit": board === null || board === void 0 ? void 0 : board.subject
      }
    }), _c('p', {
      staticClass: "mt-8 mt-lg-16 mb-20 mb-lg-40 ellip--2 page-text--lg grey-6--text line-height-15",
      staticStyle: {
        "white-space": "pre-line"
      }
    }, [_vm._v(" " + _vm._s(board === null || board === void 0 ? void 0 : board.subContent) + " ")]), _c('v-row', {
      staticClass: "row--x-small",
      attrs: {
        "align": "center"
      }
    }, [_c('v-col', [_c('v-row', {
      staticClass: "row--x-small page-text grey-6--text",
      attrs: {
        "align": "center"
      }
    }, [_c('v-col', {
      attrs: {
        "cols": "auto"
      }
    }, [_c('board-user-level', {
      attrs: {
        "user": board === null || board === void 0 ? void 0 : board.user
      }
    })], 1), _c('v-col', {
      attrs: {
        "cols": "auto"
      }
    }, [_c('p', [_vm._v(_vm._s(_vm.$dayjs(board === null || board === void 0 ? void 0 : board.createdAt).format("YYYY.MM.DD")))])])], 1)], 1), _c('v-col', {
      attrs: {
        "cols": "12",
        "md": "auto"
      }
    }, [_c('board-count', {
      attrs: {
        "recommend": "",
        "viewCount": board === null || board === void 0 ? void 0 : board.viewCount,
        "likeCount": board === null || board === void 0 ? void 0 : board.likeCount,
        "dislikeCount": board === null || board === void 0 ? void 0 : board.dislikeCount,
        "commentCount": board === null || board === void 0 ? void 0 : board.commentCount
      }
    })], 1)], 1)], 1), board !== null && board !== void 0 && (_board$content2 = board.content) !== null && _board$content2 !== void 0 && _board$content2.includes('<img src') ? _c('v-col', {
      staticClass: "mb-4 mb-md-0",
      attrs: {
        "cols": "12",
        "md": "auto",
        "order": "1",
        "order-md": "2"
      }
    }, [_c('v-card', {
      attrs: {
        "elevation": "0",
        "rounded": "",
        "width": _vm.$vuetify.breakpoint.mdAndUp ? 160 : 100
      }
    }, [_c('div', {
      staticClass: "thumb",
      staticStyle: {
        "padding-top": "100%"
      }
    }, [_c('div', {
      staticClass: "thumb__inner"
    }, [_c('v-img', {
      staticClass: "thumb__inner",
      attrs: {
        "src": _vm.selectImage(board.content)
      }
    })], 1)])])], 1) : _vm._e()], 1)], 1);
  }), 1), _c('v-row', {
    staticClass: "row--x-small flex-md-row-reverse"
  }, [_c('v-col', {
    staticClass: "d-flex justify-end mb-10 mb-md-0",
    attrs: {
      "cols": "12",
      "md": "auto"
    }
  }, [_vm.filter.tab !== 0 ? _c('v-btn', {
    staticClass: "h-lg-40px",
    attrs: {
      "outlined": "",
      "color": "primary"
    },
    on: {
      "click": _vm.certification
    }
  }, [_vm._v("글쓰기")]) : _vm._e()], 1), _c('v-spacer'), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "auto"
    }
  }, [_c('v-row', {
    staticClass: "row--x-small search--primary"
  }, [_c('v-spacer'), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "auto"
    }
  }, [_c('v-select', {
    staticClass: "v-input--small w-100 w-md-120px",
    attrs: {
      "items": _vm.searchKeys,
      "outlined": "",
      "hide-details": ""
    },
    model: {
      value: _vm.filter.searchKey,
      callback: function ($$v) {
        _vm.$set(_vm.filter, "searchKey", $$v);
      },
      expression: "filter.searchKey"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "9",
      "md": "auto"
    }
  }, [_c('v-text-field', {
    staticClass: "v-input--small w-100 w-md-240px",
    attrs: {
      "outlined": "",
      "hide-details": "",
      "placeholder": "검색어를 입력하세요.",
      "disabled": !_vm.filter.searchKey
    },
    model: {
      value: _vm.filter.searchValue,
      callback: function ($$v) {
        _vm.$set(_vm.filter, "searchValue", $$v);
      },
      expression: "filter.searchValue"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "3",
      "md": "auto"
    }
  }, [_c('v-btn', {
    staticClass: "h-100 w-100 min-w-md-60px",
    attrs: {
      "color": "grey-6"
    },
    on: {
      "click": function ($event) {
        return _vm.search();
      }
    }
  }, [_c('span', {
    staticClass: "white--text font-size-14"
  }, [_vm._v("검색")])])], 1)], 1)], 1)], 1), _c('div', {
    staticClass: "v-pagination-wrap"
  }, [_c('v-pagination', {
    staticClass: "mt-4 mb-12",
    attrs: {
      "length": _vm.pageCount,
      "total-visible": 11
    },
    on: {
      "input": function ($event) {
        return _vm.search();
      }
    },
    model: {
      value: _vm.page,
      callback: function ($$v) {
        _vm.page = $$v;
      },
      expression: "page"
    }
  })], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }